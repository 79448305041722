import { CampaignOffer } from '@home-in/models'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useAddCampaignIdMutation, useGetProfileQuery } from '@redux/apis/profile'
import { useAppSelector } from '@redux/hooks'

// This hook is used to activate the Yello offer for new and existing customers
export const useActivateYelloOffer = () => {
  const data = useAppSelector((state) => state.profile)
  const [addCampaignId] = useAddCampaignIdMutation()

  useEffect(() => {
    const accountExistsInPega = !!data?.first_name
    const yelloOfferActivated = data?.campaigns?.some(
      (campaign) => campaign.campaign_id === CampaignOffer.YelloOffer001
    )

    if (accountExistsInPega && !yelloOfferActivated) {
      const isUtmIdYello = data?.utm_id === CampaignOffer.YelloOffer001

      if (isUtmIdYello) {
        // New customer
        addCampaignId({ campaignId: CampaignOffer.YelloOffer001 })
      } else {
        // Note: we can use cookies since existing customers don't have the "activate your email" step.
        // The login step is one click, so we can use a simple cookie to activate the offer since it's impossible
        // for a user to swap devices in the middle of the login process.
        const cookieCampaignId = Cookies.get('campaign_id')
        const yelloCampaignCookieExists = cookieCampaignId === CampaignOffer.YelloOffer001

        if (yelloCampaignCookieExists) {
          // Existing customer
          addCampaignId({ campaignId: CampaignOffer.YelloOffer001 })
        }
      }
    }
  }, [data])
}
