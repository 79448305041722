import React, { FC } from 'react'
import { LoadingWrapper } from '@elements/status-handlers/loading-wrapper'
import { Activity } from '@features/home/parts/activity'
import { useGetHomeBuyingJourneyQuery } from '@redux/apis/home'

export const HomeSellSideUI: FC = () => {
  const { isLoading, isError } = useGetHomeBuyingJourneyQuery()

  return (
    <LoadingWrapper isLoading={isLoading} isError={isError}>
      <Activity />
    </LoadingWrapper>
  )
}
