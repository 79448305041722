import React from 'react'
import { ActionButtonEnum } from '@elements/actions/action-button'
import { Home } from '@features/home/layouts/home'
import { HomeSellSideUI } from '@features/home/layouts/sell-side/home-sell-side'
import { useActivateYelloOffer } from '@hooks/useActivateYelloOffer'
import { useDebouncedRedirect } from '@hooks/useDebouncedRedirect'
import { useSellUiChanges } from '@hooks/useSellUIChanges'
import { useAppSelector } from '@redux/hooks'
import { isLender } from '@utils/helpers/user.helpers'
import type { NextPage } from 'next'

const HomePage: NextPage = () => {
  const uiChangesFromSell = useSellUiChanges()
  const user = useAppSelector((state) => state.auth.user)

  useActivateYelloOffer()
  useDebouncedRedirect({
    redirect: '/lender/customers',
    condition: isLender(user),
    dependencies: [user],
  })

  return uiChangesFromSell ? <HomeSellSideUI /> : <Home openTab={1} />
}

export async function getStaticProps() {
  return {
    props: {
      showHbjTitle: true,
      actionButton: ActionButtonEnum.AddProperty,
      actionButtonLink: '/property/add',
      title: 'Home',
      overridePageTitleForUI: 'Your Properties',
      hideTitlePrefix: true,
      // Created a new property here to hide the title and action buttons (but only when another feature flag is active)
      hideTitleOverride: true,
    },
  }
}

export default HomePage
